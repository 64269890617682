import React, { useRef, useEffect } from 'react';
import {
  Editor,
  EditorProvider,
  BtnBold,
  BtnItalic,
  BtnUnderline,
  BtnStrikeThrough,
  BtnLink,
  BtnUndo,
  BtnRedo,
  HtmlButton,
  Separator,
  Toolbar,
  createButton,
} from 'react-simple-wysiwyg';

const TextEditor = ({ value, onChange }) => {
  const wrapperRef = useRef(null);

  // Создание кастомной кнопки (например, BtnAlignCenter)
  const BtnAlignCenter = createButton('AlignCenter', '📞', ({ $selection }) => {
    if ($selection?.nodeName === 'A') {
      document.execCommand('unlink');
    } else {
      const url = prompt('Телефон("tel:" НЕ ПРИБИРАТИ)', 'tel:');
      if (url) {
        document.execCommand('createLink', false, url);
      }
    }
  });

  // Обработчик вставки
  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');

    // Вставка чистого текста
    document.execCommand('insertText', false, text);
  };

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper) {

      const contentEditable = wrapper.querySelector('[contenteditable="true"]');
      if (contentEditable) {
        contentEditable.addEventListener('paste', handlePaste);
      }

      // Очистка обработчика при размонтировании компонента
      return () => {
        if (contentEditable) {
          contentEditable.removeEventListener('paste', handlePaste);
        }
      };
    }
  }, []);

  const handleEditorChange = (newContent) => {
    if (typeof onChange === 'function') {
      onChange(newContent);
    }
  };

  return (
    <div className='Editor' ref={wrapperRef}>
      <EditorProvider>
        <Editor
          value={value}
          onChange={handleEditorChange}
        >
          <Toolbar>
            <BtnUndo />
            <BtnRedo />
            <Separator />
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <BtnStrikeThrough />
            <Separator />
            <BtnLink />
            <BtnAlignCenter />
            <Separator />
            <HtmlButton />
          </Toolbar>
        </Editor>
      </EditorProvider>
    </div>
  );
};

export default TextEditor;
