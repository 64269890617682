// src/components/NotificationsAdd.jsx

import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import url from '../../url';
import Cookies from 'js-cookie';
import TextEditor from '../../components/TextEditor'; // Импорт нового компонента редактора

function NotificationsAdd() {
  const [notification, setNotification] = useState({
    title: "",
    text: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleTextChange = (newText) => {
    setNotification(prev => ({ ...prev, text: newText.target.value }));
  };

  const handleClick = async e => {
    e.preventDefault()
    console.log(notification.text)
    try {
      await axios.post(url + "/notifications", {
        title: notification.title,
        text: notification.text
      }, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      }
      );
      navigate('/Notifications')
    } catch (err) {

    }
  }

  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse', marginBottom: '20px' }}>
        <button onClick={handleClick} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff", padding: '10px 20px', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
          Зберегти
        </button>
      </div>
      <div className='ImputWrapper' style={{ marginBottom: '20px' }}>
        <label htmlFor="title" style={{ display: 'block', marginBottom: '5px' }}>Заголовок</label>
        <input
          id="title"
          name="title"
          value={notification.title}
          onChange={handleChange}
          style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
          placeholder="Введіть заголовок"
        />
      </div>
      <div className='ImputWrapper'>
        <label htmlFor="text" style={{ display: 'block', marginBottom: '5px' }}>Текст сповіщення</label>

        <TextEditor
          value={notification.text}
          onChange={handleTextChange}
        />

      </div>
    </div>
  );
}

export default NotificationsAdd;
