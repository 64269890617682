import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import url from '../../url';
import Cookies from 'js-cookie';
import {
  Editor,
  EditorProvider,
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  HtmlButton,
  Separator,
  Toolbar,
  createButton,
} from 'react-simple-wysiwyg';
import TextEditor from '../../components/TextEditor';



function NotificationsAdd() {
  const [notification, setNotification] = useState({
    title: "",
    text: "",
    kategory: ""
  });

  const [buttons, setButtons] = useState(['']);
  useEffect(() => {
    fecthAllnotifications()
  }, [])

  const fecthAllnotifications = async () => {
    try {
      const res = await axios.get(url + '/CNAPKategoriesGet', {headers:{email: Cookies.get('admingromadia.in.email'), password: Cookies.get('admingromadia.in.password')}})
      setButtons(JSON.parse(res.data))
    } catch (err) {
      console.log(err)
    }
  }
  const HandleChange = (e) => {
    setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const navigate = useNavigate();
  const handleClick = async e => {
    e.preventDefault() 
    try {
      await axios.post(url + "/AddAdministrates", {...notification, text :  notification.text.replaceAll("<div>",'').replaceAll("</div>",'<br>').replaceAll("<br><br><br>",'<br><br>')}, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
      navigate(-1)
    } catch (err) {

    }
  }

  function onChange(e) {
    setNotification(prev => ({ ...prev, ["text"]: e.target.value }))
  }

  const BtnAlignCenter = createButton('Link', '📞', ({ $selection }) => {
    if ($selection?.nodeName === 'A') {
      document.execCommand('unlink');
    } else {
      // eslint-disable-next-line no-alert
      document.execCommand('createLink', false, prompt('Телефон("tel:" НЕ ПРИБИРАТИ)', 'tel:') || undefined);
    }
  });
  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
        <button onClick={handleClick} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Зберегти</button>
      </div>
      <div className='ImputWrapper'>Заголовок
        <input onBlur={HandleChange} name="title"></input>
      </div>
      <div className='ImputWrapper' >Категорії звернень
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
              {buttons.map((button, index) => (
                <button value={button}  name="kategory" onClick={HandleChange} key={index} style={notification.kategory == button? {backgroundColor:'#0F6C00',color: "#fff"}:{}} className='AddEvent' >{button}</button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='ImputWrapper'>Текст послуги
      <TextEditor
          value={notification.text}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default NotificationsAdd
